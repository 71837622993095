@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary-color: #1accd9;
  --secodary-hover: #6c757d;
  --secondary-hover-btn: #1accd9;
  --primary-bg-color: #faf7f4;
  --primary-text-color: #000;
  --secondary-text-color: #000;
  --heading-text-color: #98229b;
  --input-label-color: #df9700;
  --icon-bg-color: #ffc107;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

/* =============commo btn=============== */
.common__btn {
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  background-color: transparent;
  color: var(--secondary-text-color);
  border: 1px solid var(--primary-color);
  font-weight: 500;
  border-radius: 2.25rem;
  transition: 0.6 ease-in-out;
  padding: 10px 20px;
  width: 50%;
}

.common__btn:hover {
  color: var(--primary-bg-color) !important;
  background-color: var(--secondary-hover-btn);
  transition: 0.6s all;
}

.second_button {
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  background-color: transparent;
  color: var(--secondary-text-color);
  border: 1px solid var(--primary-color);
  font-weight: 500;
  border-radius: 2.25rem;
  transition: 0.6 ease-in-out;
  padding: 10px 20px;
}

.second_button:hover {
  color: var(--primary-bg-color) !important;
  background-color: var(--secondary-hover-btn);
  transition: 0.6s all;
}

.heading__text__color {
  color: var(--heading-text-color) !important;
}

.subheading__text__color {
  color: var(--secondary-text-color) !important;
}

/* .btn.focus,
.btn:focus {
  outline: 0;
  color: var(--primary-bg-color) !important;
  box-shadow: 0 0 0 0.2rem rgb(136, 14, 79, 0.45) !important;
} */

/* Global heading sizes */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.h1,
h1 {
  font-size: 45px;
  font-weight: 600;
}

.h2,
h2 {
  font-size: 38px;
  /* 2rem */
  font-weight: 600;
}

.h3,
h3 {
  font-size: 30px;
  /* 1.75rem */
  font-weight: 500;
}

.h4,
h4 {
  font-size: 24px;
  /* 1.5rem */
  font-weight: 500;
}

.h5,
h5 {
  font-size: 20px;
  /* 1.25rem */
  font-weight: 500;
}

.h6,
h6 {
  font-size: 16px;
  /* 1rem */
  font-weight: 400;
}

.gallery__section h2,
.gallery__section p {
  color: var(--primary-bg-color);
}

/* smooth scrolling */
html {
  scroll-behavior: smooth;
  /* Enables smooth scrolling */
}

/* ========================================================================== */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none !important;
}

/* icon */
.bg__properties__color {
  background-color: #eaf7f4;
}

.icon__container__properties {
  font-size: 20px;
  width: 50px;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(232, 233, 241);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 18px 0 rgba(188, 192, 202, 0.26);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: var(--icon-bg-color);
  transition: 0.3s;
}

.icon__container__properties:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: var(--icon-bg-color);
}

.icon__container__properties:hover .icon__center__properties {
  color: white;
}

.items__subheading__properties p {
  text-align: justify;
}

.property__new__items {
  border-radius: 0px !important;
  padding: 10px !important;
}

/* HomeListing Styles */

.home__listing__card {
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 400px;
  height: 450px;
}

.home__listing__image__container {
  position: relative;
  height: 60%;
  overflow: hidden;
}

.home__listing__image__container .single__home__rent {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
  border-bottom: 1px solid #ddd;
}

.home__listing__image__container:hover .single__home__rent {
  filter: brightness(80%);
}

.for-sale-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ea723d;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
  z-index: 1;
}

.home__listing__address {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
}

.home__listing__price {
  font-size: 20px;
  color: #34b0b2;
  font-weight: bold;
}

.bottom-right-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  display: flex;
}

.favorite-icon,
.exchange-icon {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.favorite-icon {
  margin-right: 10px;
}

.favorite-icon:hover,
.exchange-icon:hover {
  transform: scale(1.2);
}

.hover-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 3;
  transition: opacity 0.3s ease;
}

.home__listing__image__container:hover .hover-button {
  opacity: 1;
}

.view-details-btn {
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  transition: background-color 0.3s ease;
}

/* navbar css */
.home__logo__navbar-logo {
  width: 100%;
  height: 45px;
}

.home__list__links__custom {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  color: #333 !important;
  transition: color 0.3s ease;
}

.home__list__links__custom::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #cfb53b;
  transition: width 0.3s ease;
  width: 0;
}

.home__list__links__custom:hover::after {
  width: 100%;
}

.property-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.2s;
}

.property-card:hover {
  transform: scale(1.02);
}

.property-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-body {
  padding: 15px;
}

.property-title {
  font-size: 1.25rem;
  margin: 0;
}

.property-address,
.property-price {
  margin: 5px 0;
}

.property-footer {
  padding: 10px 15px;
  background-color: #f8f9fa;
}

.thumbnail-wrapper {
  padding: 10px;
}

.thumbnail-image {
  width: 60px;
  height: auto;
  margin-right: 5px;
}

* {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.property-card {
  
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

.property-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
}

.status {
  background-color: #28a745;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
}

.time, .type, .price {
  margin-left: 8px;
}

.price .amount {
  color: #007bff;
  font-weight: bold;
}

.property-title {
  font-size: 20px;
  font-weight: bold;
  margin: 8px 0;
  color: #333;
}

.location {
  color: #666;
  margin-bottom: 8px;
}

.property-details {
  display: flex;
  gap: 16px;
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

.icon-text {
  display: flex;
  align-items: center;
}

.icon-bed::before {
  content: "🛏️";
  margin-right: 4px;
}

.icon-bath::before {
  content: "🛁";
  margin-right: 4px;
}

.icon-garage::before {
  content: "🚗";
  margin-right: 4px;
}

.property-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.btn {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 4px;
}

.btn.chat {
  background-color: #e6f0ff;
  border-color: #007bff;
  color: #007bff;
}

.map {
  text-align: center;
  color: #007bff;
}

.map i::before {
  content: "🗺️";
  margin-right: 4px;
}
.property-details-card {
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.property-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
}

.property-facts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 16px;
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
}

.fact {
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: bold;
  color: #333;
}

.value {
  color: #666;
}

.market-demand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.market-bar {
  background-color: #e6e6e6;
  height: 8px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  margin-bottom: 4px;
}

.demand-indicator {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #007bff;
}

.market-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  color: #666;
}

.description-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-top: 16px;
  margin-bottom: 8px;
}

.description-text {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}

.faqs-section {
  border-top: 2px solid #f0f0f0;
  padding-top: 1.5rem;
}

.faq-section-title {
  margin-bottom: 1rem;
  color: #333;
}

.faq-item {
  background-color: #f9f9f9; /* Light background for each FAQ item */
  border: 1px solid #ddd; /* Light border */
  border-radius: 5px;
  padding: 1rem;
}

.faq-header {
  display: flex;
  justify-content: space-between; /* Align question and button */
  cursor: pointer; /* Pointer on hover */
}

.faq-question {
  font-weight: 400; /* Bold for questions */
  margin: 0; /* Remove margin */
}

.faq-toggle-btn {
  background-color: transparent; /* No background */
  border: none; /* No border */
  font-size: 1.2rem; /* Size for the button */
  cursor: pointer; /* Pointer on hover */
}

.faq-answer {
  margin: 0.5rem 0 0 0; /* Add margin above answer */
  color: #555; /* Slightly darker color for answers */
}



